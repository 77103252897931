import { useMutation, useQueryClient } from "react-query"

import { useClient } from "api"
import { useAuth } from "auth"
import { CustomError, displayNotificationError } from "errors"
import { IResourcesUpdate } from "models"

import { ReponseRedirectType } from "./types"

const useUpdateResources = () =>
{
    const { token } = useAuth()
    const { update } = useClient(token)
    const queryClient = useQueryClient()

    const updateResources = async ({ practice, practitioner }: IResourcesUpdate) => update<IResourcesUpdate, ReponseRedirectType>("practices", { practice, practitioner })

    const { mutate, data, isLoading } = useMutation(updateResources, {
        onSettled: () =>
        {
            queryClient.invalidateQueries(["practice"])
        },
        onError: (error: CustomError) =>
        {
            displayNotificationError(error)
        },
    })

    return { redirectUrl: data?.redirectUrl ?? "", updateResources: mutate, isUpdating: isLoading }
}

export { useUpdateResources }