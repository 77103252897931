import { useQuery } from "react-query"

import { useClient } from "api"
import { CustomError, displayNotificationError } from "errors"
import { ISelectItem } from "models"

const usePractitioners = (orgId: string) =>
{
    const { read } = useClient()
    const queryKey = ["practitioners"]

    const { data, isLoading } = useQuery(queryKey, async () =>
    {
        const practitioners = await read<ISelectItem[]>(`aidbox/practitioners/${orgId}`)
        
        return practitioners
    }, {
        onError: (error: CustomError) =>
        {
            displayNotificationError(error)
        },
        suspense: true,
    })

    return { practitioners: data as ISelectItem[], isLoading }
}

export { usePractitioners }