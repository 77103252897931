import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"
import { FC, useEffect, useRef } from "react"

import { useUsaStates } from "value-set"

import { AddressFormErrorType, AddressFormType } from "./types"

const AddressField: FC<IProps> = ({ address, formError, updateAddress, isRequired, showAutoComplete=true }) =>
{
    const { usaStates } = useUsaStates()
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() =>
    {
        if ("google" in window)
        {
            const options: {
                componentRestrictions: { country: string }
                fields: string[]
                types: string[]
                bounds?: google.maps.LatLngBounds
                strictBounds?: boolean
            } = {
                componentRestrictions: { country: "us" },
                fields: ["address_components"],
                types: ["address"],
            }

            try
            {
                const autoComplete: google.maps.places.Autocomplete = new google.maps.places.Autocomplete(
                    inputRef.current as HTMLInputElement,
                    options,
                )

                autoComplete.addListener("place_changed", () =>
                {
                    const { address_components } = autoComplete.getPlace()
                    let route = ""
                    let streetNumber = ""
                    let autocompletedAddress: AddressFormType = { city: "", postalCode: "", line1: "", line2: "", state: "" }

                    for (const component of address_components as google.maps.GeocoderAddressComponent[])
                    {
                        const componentType = component.types[0]

                        switch (componentType)
                        {
                            case "route": {
                                route = component.long_name
                                break
                            }

                            case "street_number": {
                                streetNumber = component.long_name
                                break
                            }

                            case "postal_code": {
                                autocompletedAddress.postalCode = component.long_name
                                break
                            }

                            case "locality":
                                autocompletedAddress.city = component.long_name
                                break

                            case "administrative_area_level_1": {
                                autocompletedAddress.state = component.short_name
                                break
                            }
                        }
                    }

                    autocompletedAddress.line1 = `${streetNumber} ${route}`

                    updateAddress(autocompletedAddress)
                })
            } catch { }
        }
    }, [updateAddress])

    return <>
        <div className="grid p-fluid lg:grid-cols-2 gap-4">
            <div className="field relative">
                <label>Line1{isRequired && "*"}</label>
                <InputText ref={showAutoComplete && "google" in window ? inputRef : null} className={classNames("w-full p-inputtext-sm", { "p-invalid": formError.line1 })} value={address.line1} onChange={(event) => updateAddress({ ...address, line1: event.target.value ?? "" })} />
                {formError.line1 && <small className="p-error block absolute">Line1 is required</small>}
            </div>

            <div className="field">
                <label>Line2{isRequired && "*"}</label>
                <InputText className="w-full p-inputtext-sm" value={address.line2} onChange={(event) => updateAddress({ ...address, line2: event.target.value ?? "" })} />
            </div>
        </div>

        <div className="grid p-fluid md:grid-cols-3 gap-4">
            <div className="field relative">
                <label>City{isRequired && "*"}</label>
                <InputText className={classNames("p-inputtext-sm", { "p-invalid": formError.city })} value={address.city} onChange={(event) => updateAddress({ ...address, city: event.target.value ?? "" })} />
                {formError.city && <small className="p-error block absolute">City is required</small>}
            </div>

            <div className="field relative">
                <label>State{isRequired && "*"}</label>
                <Dropdown showClear filter value={address.state} options={usaStates} className={classNames("p-inputtext-sm", { "p-invalid": formError.state })} onChange={(e: { value: string }) => updateAddress({ ...address, state: e.value ?? "" })} />
                {formError.state && <small className="p-error block absolute">State is required</small>}
            </div>

            <div className="field relative">
                <label>Postal Code{isRequired && "*"}</label>
                <InputText className={classNames("p-inputtext-sm", { "p-invalid": formError.postalCode })} value={address.postalCode} onChange={(event) => updateAddress({ ...address, postalCode: event.target.value ?? "" })} />
                {formError.postalCode && <small className="p-error block absolute">Postal code is required</small>}
            </div>
        </div>
    </>
}

interface IProps
{
    isRequired?: boolean
    address: AddressFormType
    formError: AddressFormErrorType
    updateAddress(address: AddressFormType): void
    showAutoComplete?: boolean
}

export { AddressField }