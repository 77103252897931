import { IPractitioner, ITelecom } from "models"
import { EMAIL_CONTACT_POINT_SYSTEM, PHONE_CONTACT_POINT_SYSTEM } from "utils"
import { AddressFormErrorType, AddressFormType } from "commons"

const getInitialPractitionerState = (): PractitionerFormType => ({
    firstName: "",
    lastName: "",
    middleName: "",
    emails: [{ value: "", system: EMAIL_CONTACT_POINT_SYSTEM, use: "work" }],
    phones: [{ value: "", system: PHONE_CONTACT_POINT_SYSTEM, use: "work" }],
    address: { city: "", postalCode: "", line1: "", line2: "", state: "" }
})

const toPractitioner = ({ address, emails, phones, ...rest }: PractitionerFormType): IPractitioner =>
{
    const telecom = [...emails, ...phones]
    const practAddress = address.line1 ? [{ ...address, country: "US", use: "home" }] : undefined

    return {
        ...rest,
        address: practAddress,
        telecom
    }
}

const initialPractitionerFormErrors: PractitionerFormErrorType = {
    firstName: false,
    lastName: false,
    line1: false,
    city: false,
    state: false,
    postalCode: false,
    gender: false,
}

export type PractitionerFormType = {
    firstName: string
    middleName: string
    lastName: string
    gender?: string,
    emails: ITelecom[],
    phones: ITelecom[],
    address: AddressFormType,
}

export type PractitionerFormErrorType = {
    firstName: boolean
    lastName: boolean
    gender: boolean,
} & AddressFormErrorType

export { getInitialPractitionerState, toPractitioner, initialPractitionerFormErrors }