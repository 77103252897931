import { useQuery } from "react-query"

import { useClient } from "api"
import { CustomError, displayNotificationError } from "errors"

import { PracticeSettings } from "../types"

const useSettings = (orgId: string) =>
{
    const { read } = useClient()

    const queryKey = ["settings", orgId]

    const { data, isLoading } = useQuery(queryKey, async () =>
    {
        const settings = await read<PracticeSettings>(`aidbox/resolve-setting/${orgId}`)

        return settings
    }, {
        enabled: !!orgId,
        onError: (error: CustomError) =>
        {
            displayNotificationError(error)
        }
    })

    return { settings: data, isLoading }
}

export { useSettings }