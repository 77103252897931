import React from "react"
import { InputText } from "primereact/inputtext"
import { classNames } from "primereact/utils"

import { AddressField, AddressFormType, TelecomContainer } from "commons"
import { ITelecom } from "models"

import { OrganizationFormType, OrganizationFormErrorType } from "./validation"

const OrganizationForm: React.FC<Props> = ({ formValues, setFormValues, formError }) =>
{
    const updateTelecoms = (emails: ITelecom[], phones: ITelecom[]) =>
    {
        setFormValues((prev) => ({ ...prev, emails, phones }))
    }

    const updateAddress = (address: AddressFormType) =>
    {
        setFormValues((prev) => ({ ...prev, address }))
    }
    
    return <div className="flex flex-col w-full space-y-3">
        <h5 className="my-10 text-2xl font-medium">Organization information</h5>

        <h6 className="text-lg w-full font-medium">Basic Information</h6>
        <hr className="bg-gray-300" />

        <div className="field">
            <label>Organization Name*</label>
            <InputText className={classNames("w-full p-inputtext-sm", { "p-invalid": formError.name })} value={formValues.name} onChange={(event) => setFormValues((prev) => ({ ...prev, name: event.target.value ?? "" }))} />
            {formError.name && <small className="p-error block absolute">Organization name is required</small>}
        </div>

        <h6 className="text-lg w-full font-medium">Address</h6>
        <hr className="bg-gray-300" />

        <AddressField address={formValues.address} formError={formError} updateAddress={updateAddress} isRequired />

        <h6 className="text-lg w-full font-medium">Contact Information</h6>
        <hr className="bg-gray-300" />

        <TelecomContainer phones={formValues.phones} emails={formValues.emails} setTelecoms={updateTelecoms} />
    </div>
}

type Props = {
    formValues: OrganizationFormType
    formError: OrganizationFormErrorType
    setFormValues: React.Dispatch<React.SetStateAction<OrganizationFormType>>
}

export { OrganizationForm }