import { useMutation } from "react-query"

import { useClient } from "api"
import { CustomError, displayNotificationError } from "errors"

import { Parameters } from "../commons/types"


const useCheckEmailExists = (onSettled?: (data: boolean) => void) =>
{
    const { post } = useClient()

    const checkEmail = async ({
        emailToCheck,
        patientOrganization
    }: {
        emailToCheck?: string
        patientOrganization?: {id: string, name: string}
    }) => {
        if (!emailToCheck) return false

        const parameters: Parameters = {
            resourceType: "Parameters",
            parameter: [
                {
                    name: "email",
                    value: {
                        string: emailToCheck,
                    },
                },
                { name: "resourceType", value: { string: !patientOrganization ? "Practitioner" : "Patient" } },
                ...(patientOrganization
                    ? [{ name: "organization", value: { Reference: { display: patientOrganization.name, id: patientOrganization.id, resourceType: "Organization" } } }]
                    : []),
            ],
        }

        const response = await post<Parameters, { exists: boolean }>("aidbox/email-exists", parameters)

        return response.exists ?? false
    }

    const {
        mutate: checkEmailExists,
        isLoading,
        data,
    } = useMutation({
        mutationFn: checkEmail,
        onError: (error: CustomError) => {
            const code = (error as CustomError).cause?.name
            if (!code.includes("404"))
              displayNotificationError(error)
        },
        onSettled: (data) => onSettled?.(data ?? false),
    })

    return { checkEmailExists, isCheckingEmail: isLoading, exists: data ?? false }
}

export { useCheckEmailExists }