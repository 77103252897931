import { useMemo } from "react"
import { Parameters, ResourceObject } from "../commons/types"

import { Client } from "./client"

const useClient = (token?: string) =>
{
    const client = useMemo(() => new Client(token), [token])

    const read = <T>(endpoint: string) => client.read<T>(endpoint)

    const post = <T, R>(endpoint: string, resource: T) => client.post<T, R>(endpoint, resource)

    const update = <T, R>(endpoint: string, resource: T) => client.update<T, R>(endpoint, resource)

    return { read, post, update }
}

export { useClient }