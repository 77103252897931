import { useQuery } from "react-query"

import { useClient } from "api"
import { CustomError, displayNotificationError } from "errors"
import { IPractice } from "models"
import { useAuth } from "auth"

const useOrganization = () =>
{
    const { token } = useAuth()
    const { read } = useClient(token)
    const queryKey = ["practice"]

    const { data, isLoading } = useQuery(queryKey, async () =>
    {
        const organization = await read<IPractice>("practices")
        
        if (!organization)
        {
            throw new Error("Not found", {
                cause: {
                    name: "404",
                    message: "Organization not found",
                },
            })
        }
        return organization
    }, {
        onError: (error: CustomError) =>
        {
            displayNotificationError(error)
        },
        suspense: true,
    })

    return { organization: data as IPractice, isLoading }
}

export { useOrganization }