import React from "react"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"

import { AddressField, AddressFormType, TelecomContainer } from "commons"
import { ITelecom } from "models"
import { genders } from "utils"

import { PractitionerFormType, PractitionerFormErrorType } from "./validation"

const PractitionerForm: React.FC<Props> = ({ formValues, setFormValues, formError }) =>
{
    const updateTelecoms = (emails: ITelecom[], phones: ITelecom[]) =>
    {
        setFormValues((prev) => ({ ...prev, emails, phones }))
    }

    const updateAddress = (address: AddressFormType) =>
    {
        setFormValues((prev) => ({ ...prev, address }))
    }

    return <div className="flex flex-col w-full space-y-3">
        <h5 className="my-10 text-2xl font-medium">Practitioner information</h5>

        <h6 className="text-lg w-full font-medium">Basic Information</h6>
        <hr className="bg-gray-300" />

        <div className="grid p-fluid lg:grid-cols-2 gap-4">
            <div className="field relative">
                <label>First Name*</label>
                <InputText className={classNames("w-full p-inputtext-sm", { "p-invalid": formError.firstName })} value={formValues.firstName} onChange={(event) => setFormValues((prev) => ({ ...prev, firstName: event.target.value ?? "" }))} />
                {formError.firstName && <small className="p-error block absolute">Fiest name is required</small>}
            </div>
            <div className="field relative">
                <label>Last Name*</label>
                <InputText className={classNames("w-full p-inputtext-sm", { "p-invalid": formError.lastName })} value={formValues.lastName} onChange={(event) => setFormValues((prev) => ({ ...prev, lastName: event.target.value ?? "" }))} />
                {formError.lastName && <small className="p-error block absolute">Last name is required</small>}
            </div>
        </div>

        <div className="grid p-fluid lg:grid-cols-2 gap-4">
            <div className="field">
                <label>Middle Name</label>
                <InputText className={classNames("w-full p-inputtext-sm")} value={formValues.middleName} onChange={(event) => setFormValues((prev) => ({ ...prev, middleName: event.target.value ?? "" }))} />
            </div>

            <div className="field">
                <label>Gender</label>
                <Dropdown showClear value={formValues.gender} options={genders} className={classNames("p-inputtext-sm", { "p-invalid": formError.gender })} onChange={(e: { value: string }) => setFormValues(prev => ({ ...prev, gender: e.value ?? "" }))} />
                {formError.gender && <small className="p-error block absolute">Gender is required</small>}
            </div>
        </div>

        <h6 className="text-lg w-full font-medium">Address</h6>
        <hr className="bg-gray-300" />

        <AddressField address={formValues.address} formError={formError} updateAddress={updateAddress} />

        <h6 className="text-lg w-full font-medium">Contact Information</h6>
        <hr className="bg-gray-300" />

        <TelecomContainer phones={formValues.phones} emails={formValues.emails} setTelecoms={updateTelecoms} checkEmailExists />
    </div>
}

type Props = {
    formValues: PractitionerFormType
    formError: PractitionerFormErrorType
    setFormValues: React.Dispatch<React.SetStateAction<PractitionerFormType>>
}

export { PractitionerForm }