import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react"

import { TelecomErrorType } from "types"

const TelecomErrorsContext = createContext<State | undefined>(undefined)
TelecomErrorsContext.displayName = "TelecomErrorsContext"

const TelecomErrorsProvider: React.FC<PropsWithChildren<IProps>> = ({ children, telecomErrors, setTelecomErrors, isCheckingEmail, setIsCheckingEmail }) =>
{
    return <TelecomErrorsContext.Provider value={{ telecomErrors, setTelecomErrors, isCheckingEmail, setIsCheckingEmail }}>{children}</TelecomErrorsContext.Provider>
}

const useTelecomErrors = () =>
{
    const context = useContext(TelecomErrorsContext)

    if (!context)
    {
        throw new Error("TelecomErrorsProvider must be used within a PatientFormValidationProvider")
    }

    return context
}

type ItelecomError = { emails: TelecomErrorType[], phones: TelecomErrorType[] }

type State = {
    isCheckingEmail: boolean
    setIsCheckingEmail: Dispatch<SetStateAction<boolean>>
    telecomErrors: ItelecomError
    setTelecomErrors: React.Dispatch<React.SetStateAction<ItelecomError>>
}

type IProps = State

export { TelecomErrorsProvider, useTelecomErrors }