import { faExternalLink, faSignOut } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const UnauthorizedErrorView = () =>
{
    return (
        <div className="flex items-center justify-center w-full h-screen">
            <div className="text-center">
                <p className="text-primary">401 Error</p>
                <h1 className="text-4xl py-2 font-bold">Unauthorized</h1>
                <p className="text-sm text-gray-400 py-2 pb-4">
                    Sorry, you do not have access
                </p>
                <a href="/" className="p-button text-sm button-primary">
                    <span>
                        Go to Home <FontAwesomeIcon icon={faExternalLink} className="pl-1" />
                    </span>
                </a>
            </div>
        </div>
    )
}

export { UnauthorizedErrorView }
