import { useMutation, useQueryClient } from "react-query"

import { useClient } from "api"
import { IPatient } from "models"
import { CustomError, displayNotificationError } from "errors"
import { ReponseRedirectType } from "hooks"

const useCreatePatient = (onSuccess: (patient: ReponseRedirectType) => void) =>
{
    const { post } = useClient()

    const patient = async (patient: IPatient) => post<IPatient, ReponseRedirectType>("patients", patient)

    const { mutate, isLoading } = useMutation(patient, {
        onSuccess: (response) => onSuccess(response),
        onError: (error: CustomError) =>
        {
            displayNotificationError(error)
        },
    })

    return { createPatient: mutate, isLoading }
}

export { useCreatePatient }