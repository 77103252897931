import { IPatient } from "models"
import { EMAIL_CONTACT_POINT_SYSTEM, PHONE_CONTACT_POINT_SYSTEM } from "utils"

import { PatientFormErrorType, PatientFormType } from "../types"

const getInitialPatientState = (orgId: string, practitioner: string): PatientFormType => ({
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    emails: [{ value: "", system: EMAIL_CONTACT_POINT_SYSTEM, use: "work" }],
    phones: [{ value: "", system: PHONE_CONTACT_POINT_SYSTEM, use: "work" }],
    birthdate: "",
    organization: orgId,
    practitioner: practitioner,
    address: { city: "", postalCode: "", line1: "", line2: "", state: "" }
})

const toPatient = ({ address, emails, phones, birthdate, ...rest }: PatientFormType): IPatient =>
{
    const telecom = [...emails, ...phones]
    const patientAddress = address.line1 ? [{ ...address, country: "US", use: "home" }] : undefined

    return {
        ...rest,
        address: patientAddress,
        telecom,
        birthdate
    }
}

const getInitialPatientFormErrors: PatientFormErrorType =
{
    firstName: false,
    lastName: false,
    line1: false,
    city: false,
    state: false,
    gender: false,
    postalCode: false,
    birthdate: false,
    practitioner: false,
}

export { getInitialPatientState, toPatient, getInitialPatientFormErrors }