import React from "react"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"
import { classNames } from "primereact/utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBuilding } from "@fortawesome/pro-light-svg-icons"

import { AddressFormType, BirthdateField, TelecomContainer, AddressField } from "commons"
import { ITelecom } from "models"
import { genders } from "utils"
import { usePractitioners } from "practitioner"
import { useOrganizationName } from "organization"

import { PatientFormType, PatientFormErrorType } from "../types"

const PatientForm: React.FC<Props> = ({ formValues, setFormValues, formError }) =>
{
    const { practitioners } = usePractitioners(formValues.organization)
    const { organizationName } = useOrganizationName(formValues.organization)

    const updateBirthdate = (date: string) =>
    {
        setFormValues((prev) => ({ ...prev, birthdate: date }))
    }

    const updateTelecoms = (emails: ITelecom[], phones: ITelecom[]) =>
    {
        setFormValues((prev) => ({ ...prev, emails, phones }))
    }

    const updateAddress = (address: AddressFormType) =>
    {
        setFormValues((prev) => ({ ...prev, address }))
    }

    return <div className="flex flex-col w-full space-y-3">
        <div className="my-10">
            <h5 className="text-2xl font-medium">Patient Portal Registration</h5>
            <span title="Organization" className="text-xl text-slate-600"><FontAwesomeIcon icon={faBuilding} /> {organizationName ?? "No name"}</span>
        </div>

        <h6 className="text-lg w-full font-medium">Basic Information</h6>
        <hr className="bg-gray-300" />

        <div className="grid p-fluid">
            <div className="field relative">
                <label>Practitioner*</label>
                <Dropdown filter value={formValues.practitioner} options={practitioners} className={classNames("p-inputtext-sm", { "p-invalid": formError.practitioner })} onChange={(e: { value: string }) => setFormValues(prev => ({ ...prev, practitioner: e.value ?? "" }))} />
                {formError.practitioner && <small className="p-error block absolute">Practitioner is required</small>}
            </div>
        </div>

        <div className="grid p-fluid lg:grid-cols-3 gap-4">
            <div className="field relative">
                <label>First Name*</label>
                <InputText className={classNames("w-full p-inputtext-sm", { "p-invalid": formError.firstName })} value={formValues.firstName} onChange={(event) => setFormValues((prev) => ({ ...prev, firstName: event.target.value ?? "" }))} />
                {formError.firstName && <small className="p-error block absolute">First name is required</small>}
            </div>

            <div className="field relative">
                <label>Middle Name</label>
                <InputText className={classNames("w-full p-inputtext-sm")} value={formValues.middleName} onChange={(event) => setFormValues((prev) => ({ ...prev, middleName: event.target.value ?? "" }))} />
            </div>

            <div className="field relative">
                <label>Last Name*</label>
                <InputText className={classNames("w-full p-inputtext-sm", { "p-invalid": formError.lastName })} value={formValues.lastName} onChange={(event) => setFormValues((prev) => ({ ...prev, lastName: event.target.value ?? "" }))} />
                {formError.lastName && <small className="p-error block absolute">Last name is required</small>}
            </div>
        </div>

        <div className="grid p-fluid lg:grid-cols-2 gap-4">
            <div className="field">
                <BirthdateField label="Birthdate*" setBirthdate={updateBirthdate} error={formError.birthdate} />
            </div>

            <div className="field relative">
                <label>Gender*</label>
                <Dropdown showClear value={formValues.gender} options={genders} className="p-inputtext-sm" onChange={(e: { value: string }) => setFormValues(prev => ({ ...prev, gender: e.value ?? "" }))} />
                {formError.gender && <small className="p-error block absolute">Gender is required</small>}
            </div>
        </div>

        <h6 className="text-lg w-full font-medium">Address</h6>
        <hr className="bg-gray-300" />

        <AddressField address={formValues.address} formError={formError} updateAddress={updateAddress} />

        <h6 className="text-lg w-full font-medium">Contact Information</h6>
        <hr className="bg-gray-300" />

        <TelecomContainer phones={formValues.phones} emails={formValues.emails} setTelecoms={updateTelecoms} checkEmailExists patientOrganization={{ id: formValues.organization, name: organizationName ?? "" }} />
    </div>
}

type Props = {
    formValues: PatientFormType
    formError: PatientFormErrorType
    setFormValues: React.Dispatch<React.SetStateAction<PatientFormType>>
}

export { PatientForm }