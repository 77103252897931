import { useCallback, useState } from "react"
import { Button } from "primereact/button"

import { getInitialOrganizationState, OrganizationForm, useOrganization, toPractice, initialOrganizationFormErrors } from "organization"
import { PractitionerForm, getInitialPractitionerState, initialPractitionerFormErrors, toPractitioner } from "practitioner"
import { getInitialTelecomErrors, ONBOARD_VIEWS } from "utils"
import { useUpdateResources } from "hooks"
import { TelecomErrorsProvider } from "commons"
import { ApplySettings } from "settings"

import { Onboarded } from "./Onboarded"

const OnboardOrganization: React.FC = () =>
{
    const { organization } = useOrganization()
    const [organizationFormValues, setOrganizationFormValues] = useState(getInitialOrganizationState(organization))
    const [organizationFormError, setOrganizationFormError] = useState(initialOrganizationFormErrors)
    const [orgTelecomErrors, setOrgTelecomErrors] = useState(getInitialTelecomErrors(organizationFormValues.emails, organizationFormValues.phones))
    const { redirectUrl, updateResources, isUpdating } = useUpdateResources()

    const [practitionerFormValues, setPractitionerFormValues] = useState(getInitialPractitionerState())
    const [practitionerFormError, setPractitionerFormError] = useState(initialPractitionerFormErrors)
    const [practTelecomErrors, setPractTelecomErrors] = useState(getInitialTelecomErrors(practitionerFormValues.emails, practitionerFormValues.phones))

    const [step, setStep] = useState(1)
    const [isCheckingEmail, setIsCheckingEmail] = useState(false)

    const isLoading = isUpdating || isCheckingEmail

    const isOrganizationValid = useCallback(() =>
    {
        let errors = { ...initialOrganizationFormErrors }
        let isValid = true

        if (!organizationFormValues.name)
        {
            errors.name = true
            isValid = false
        }

        if (!organizationFormValues.address.line1)
        {
            errors.line1 = true
            isValid = false
        }

        if (!organizationFormValues.address.city)
        {
            errors.city = true
            isValid = false
        }

        if (!organizationFormValues.address.state)
        {
            errors.state = true
            isValid = false
        }

        if (!organizationFormValues.address.postalCode)
        {
            errors.postalCode = true
            isValid = false
        }

        if (orgTelecomErrors.emails.some(({ hasError }) => hasError))
        {
            isValid = false
        }

        if (orgTelecomErrors.phones.some(({ hasError }) => hasError))
        {
            isValid = false
        }

        setOrganizationFormError((prev) => ({ ...prev, ...errors }))

        return isValid
    }, [organizationFormValues, setOrganizationFormError, orgTelecomErrors])

    const isPractitionerValid = useCallback(() =>
    {
        let errors = { ...initialPractitionerFormErrors }
        let isValid = true

        if (!practitionerFormValues.firstName)
        {
            errors.firstName = true
            isValid = false
        }

        if (!practitionerFormValues.lastName)
        {
            errors.lastName = true
            isValid = false
        }

        if (practitionerFormValues.address.line1 || practitionerFormValues.address.line2 || practitionerFormValues.address.city || practitionerFormValues.address.state || practitionerFormValues.address.postalCode)
        {
            if (!practitionerFormValues.address.line1)
            {
                errors.line1 = true
                isValid = false
            }

            if (!practitionerFormValues.address.city)
            {
                errors.city = true
                isValid = false
            }

            if (!practitionerFormValues.address.state)
            {
                errors.state = true
                isValid = false
            }

            if (!practitionerFormValues.address.postalCode)
            {
                errors.postalCode = true
                isValid = false
            }
        }

        if (practTelecomErrors.emails.some(({ hasError }) => hasError))
        {
            isValid = false
        }

        if (practTelecomErrors.phones.some(({ hasError }) => hasError))
        {
            isValid = false
        }

        setPractitionerFormError((prev) => ({ ...prev, ...errors }))

        return isValid
    }, [practitionerFormValues, setPractitionerFormError, practTelecomErrors])

    const onSubmit = () =>
    {
        setOrgTelecomErrors((prev) => ({ emails: prev.emails.map((email) => ({ ...email, isTouched: true })), phones: prev.phones.map((phone) => ({ ...phone, isTouched: true })) }))
        setPractTelecomErrors((prev) => ({ emails: prev.emails.map((email) => ({ ...email, isTouched: true })), phones: prev.phones.map((phone) => ({ ...phone, isTouched: true })) }))

        const isValid = isOrganizationValid() && isPractitionerValid()

        if (isValid)
        {
            const practitioner = toPractitioner(practitionerFormValues)
            const practice = toPractice(organization, organizationFormValues)

            updateResources({ practice, practitioner })
        }
    }

    const renderForm = useCallback(() =>
    {
        switch (step)
        {
            case 1:
                return <OrganizationForm formValues={organizationFormValues} setFormValues={setOrganizationFormValues} formError={organizationFormError} />
            case 2:
                return <PractitionerForm formValues={practitionerFormValues} setFormValues={setPractitionerFormValues} formError={practitionerFormError} />
            default:
                return <p>Not implemented yet</p>
        }
    }, [
        step,
        organizationFormValues,
        setOrganizationFormValues,
        organizationFormError,
        practitionerFormValues,
        setPractitionerFormValues,
        practitionerFormError
    ])

    const nextStep = () =>
    {
        if (isOrganizationValid())
        {
            setStep(2)
        }
    }

    return <>
        <ApplySettings orgId={organization.id} />
        {organization.active === false ?  <>
                <form className="w-full space-y-5">
                    <TelecomErrorsProvider isCheckingEmail={isCheckingEmail} setIsCheckingEmail={setIsCheckingEmail} telecomErrors={step === 1 ? orgTelecomErrors : practTelecomErrors} setTelecomErrors={step === 1 ? setOrgTelecomErrors : setPractTelecomErrors}>
                        {renderForm()}
                    </TelecomErrorsProvider>

                </form>

                <hr className="bg-gray-300 my-10" />

                <div className="flex justify-end items-center w-full space-x-5">
                    {step > 1 && <Button className="w-full md:w-min" type="button" label="Preview" onClick={() => setStep(1)} disabled={isLoading} />}
                    <Button className="w-full md:w-min" type="button" label={step === 1 ? "Next" : "Finish"} onClick={step === 1 ? nextStep : onSubmit} loading={isLoading} />
                </div>
        </> : <Onboarded redirectUrl={redirectUrl} name={organization.name} onboardView={ONBOARD_VIEWS.ORGANIZATION} email={practitionerFormValues.emails?.[0]?.value} />
           }
    </>
}

export { OnboardOrganization }
