import { ErrorBoundary } from 'react-error-boundary'
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import { QueryClient, QueryClientProvider, QueryErrorResetBoundary } from 'react-query'

import { ErrorContainer } from 'errors'
import { LoadingView } from 'commons'

import "./index.css"
import "primereact/resources/themes/lara-light-blue/theme.css"
import "./prime-react-custom-theme.css"
import "react-toastify/dist/ReactToastify.css"

import { App } from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)
const queryClient = new QueryClient()

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <QueryErrorResetBoundary>
                    {({ reset }) => (
                        <ErrorBoundary
                            FallbackComponent={ErrorContainer}
                            onReset={reset}
                        >
                            <Suspense fallback={<LoadingView />}>
                                <App />
                            </Suspense>
                        </ErrorBoundary>
                    )}
                </QueryErrorResetBoundary>
            </QueryClientProvider>
        </BrowserRouter>
    </React.StrictMode>)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
