import { useQuery } from "react-query"

import { useClient } from "api"
import { CustomError, displayNotificationError } from "errors"
import { ISelectItem } from "models"

const useUsaStates = () =>
{
    const { read } = useClient()
    const queryKey = ["usa-states", "continental-usa-states"]

    const { data, isLoading } = useQuery(queryKey, async () =>
    {
        const states = await read<ISelectItem[]>("aidbox/value-set/continental-usa-states")
        
        return states
    }, {
        onError: (error: CustomError) =>
        {
            displayNotificationError(error)
        },
    })

    return { usaStates: data as ISelectItem[], isLoading }
}

export { useUsaStates }