import { useQuery } from "react-query"

import { useClient } from "api"
import { CustomError, displayNotificationError } from "errors"

const useOrganizationName = (id: string) =>
{
    const { read } = useClient()
    const queryKey = ["practice-name", id]

    const { data, isLoading } = useQuery(queryKey, async () =>
    {
        const { name } = await read<{ name: string }>(`organizations/name-by-id/${id}`)

        return name
    }, {
        onError: (error: CustomError) =>
        {
            displayNotificationError(error)
        },
        suspense: true,
    })

    return { organizationName: data, isLoading }
}

export { useOrganizationName }